<template>
    <div class="container">
        <div class="item">
            <van-cell title="故障描述">
                <template #label> <span v-html="desc" /> </template>
            </van-cell>
            <van-cell title="解决方案">
                <template #label> <span v-html="resolve" /> </template>
            </van-cell>
        </div>
        <div class="item">
            <van-cell title="辅助指导材料">
                <template #label>
                    <div v-for="item in material" :key="item.id">
                        <van-image :src="item.image" class="material-img" />
                    </div>
                </template>
            </van-cell>
        </div>
    </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import { useRoute } from "vue-router";
import { wordWrap } from "@/utils";
export default {
    name: "FaultDetail",
    setup() {
        let state = reactive({
            id: "",
            desc: "",
            resolve: "",
            material: [],
        });

        function getFaultDetail() {
            state.desc = wordWrap(`指纹识别不灵敏`);
            state.resolve = wordWrap(`1.指纹录入时，录入指纹5次以上
            2.75岁以上老人建议使用CPU卡片入户`);
            state.material = [
                { id: 1, image: require("@/assets/fault/banner.png") },
                { id: 2, image: require("@/assets/fault/banner.png") },
            ];
        }

        state.id = useRoute().query?.id || "";
        getFaultDetail();

        return {
            ...toRefs(state),
        };
    },
};
</script>

<style lang="less" scoped>
@import url("@/styles/mixin.less");
.container {
    flex: 1;
    background-color: #f2f2f2;
    .item {
        display: block;
        box-shadow: 2px 2px 8px #ccc;
        background-color: #ffffff;
        margin: 10px;
        border-radius: 5px;
        padding: 0;
        overflow: hidden;
        font-size: 14px;
        .van-cell {
            padding: 20px;
        }
        .material-img {
            display: block;
            margin-top: 15px;
        }
    }
}
</style>